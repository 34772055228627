export type Id<T extends string> = string & {
  __id_tag: T;
};
export const UNSAFE_stringToId = <T extends string>(s: string): Id<T> => s as Id<T>;

export interface MarkAttrs {
  href: string;
  title: string;
}

export interface Mark {
  type: 'em' | 'strong' | 'link';
  attrs: MarkAttrs;
}

export type InlineNode = {
  text: string;
  marks: Array<Mark>;
};

export interface ParagraphNode {
  type: 'ParagraphNode';
  content: Array<InlineNode>;
}

export interface BlockQuoteNode {
  type: 'BlockQuoteNode';
  content: Array<ParagraphNode>;
}

export type BlockNode = ParagraphNode | BlockQuoteNode;

export interface Tag {
  internalTag: boolean;
  text: string;
}

export interface Show {
  id: Id<'Show'>;
  description: string | null;
  posterURL: string;
  slug: string;
  tags: Array<Tag>;
  title: string;
  thumbnailURL: string;
}

export type Section = {
  id: Id<'Section'>;
  title: string;
  slug: string;
  imageTreatment: string | null;
  position: number;
};

export interface Member {
  headshot: string;
  name: string;
  title: string;
  twitterHandle: string;
}

export interface Host {
  name: string;
  bio: string;
  imageURL: string;
}

export interface OldHost {
  name: string;
  bio: string;
  image: string;
}

export interface PodcastTag {
  title: string;
  image: string;
  url: string;
}

export interface SubscribeLink {
  type: string;
  title: string;
  url: string;
}

export interface Podcast {
  name: string;
  slug: string;
  description: string;
  episodesUrl: string;
  coverArt: string;
  acastShowId: string;
  iheartradioShowId: string;
  position: number;
  subscribeLinks: Array<SubscribeLink>;
  signup: {
    listId: number;
    title: string;
    message: string;
    successTitle: string;
    successMessage: string;
    submitButtonMessage: string;
    placeholder: string;
  };
  hosts: Array<Host>;
  tags: Array<PodcastTag>;
  showId: Id<'Show'>;
  transcript: Array<BlockNode>;
}

export interface OldPodcast {
  name: string;
  slug: string;
  description: string;
  episodesUrl: string;
  poster: string;
  signup: {
    listId: number;
    title: string;
    message: string;
    successTitle: string;
    successMessage: string;
    submitButtonMessage: string;
    placeholder: string;
  };
  hosts: Array<OldHost>;
  tags: Array<PodcastTag>;
}

export interface PodcastDefault {
  tags: Array<string>;
  signup: {
    listId: number;
    title: string;
    message: string;
    successTitle: string;
    successMessage: string;
    submitButtonMessage: string;
    placeholder: string;
  };
}

type BasePodcastEpisode = {
  id: Id<'PodcastEpisode'>;
  description: string;
  title: string;
  coverArt: string | null;
  transcript: Array<BlockNode>;
  slug: string;
  acastEpisodeId: string | null;
  audioURL: string | null;
  episodeNumber: number | null;
  seasonNumber: number | null;
  podcastShowId: number;
  publishedAt: number;
};
type PodcastEpisodeResolves = {
  podcastShow: Podcast;
};
export type PodcastEpisode<T extends keyof PodcastEpisodeResolves = never> = BasePodcastEpisode &
  Pick<PodcastEpisodeResolves, T>;

export interface NewsletterData {
  scheduledAt: number;
  subject: string;
  url: string;
}
export interface NewslettersArchiveData {
  newsletters: Array<NewsletterData>;
}

export interface Player {
  on: (event: string, cb: Function) => void;
  hasStarted: () => boolean;
  error: () => {
    message: string;
  };
}

export type VideoRendition = {
  id: Id<'VideoRendition'>;
  height: number | null;
  type: string;
  url: string;
  width: number | null;
  jobId: string | null;
};

type BaseVideo = {
  id: Id<'Video'>;
  description: string | null;
  disableAds: boolean;
  duration: number;
  index: number;
  publishedAt: number;
  shareURL: string;
  thumbnailURL: string;
  title: string;
  url: string;
  slug: string;
  transcript: string;
  article: Array<BlockNode>;
};
type VideoResolves = {
  show: Show;
  tags: Array<Tag>;
  renditions: Array<VideoRendition>;
  sections: Array<Section>;
};
export type Video<T extends keyof VideoResolves = never> = BaseVideo & Pick<VideoResolves, T>;

export interface LiveVideo {
  id: Id<'Video'>;
  duration: number;
  location: string;
  publishedAt: number;
  shareURL: string;
  thumbnailURL: string;
  title: string;
  url: string;
  slug: string;
}

export interface LiveStream {
  id: number;
  title: string;
  description: string;
  thumbnail: string;
  live: boolean;
}
