import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { LiveVideo, Video } from 'types';
import Icon from 'components/Icon';
import { imageUrl, formatDateTime } from 'shared/util';
import { Skeleton } from 'components/Skeleton';
import Image from 'next/future/image';

import styles from './index.module.scss';

const useRefDimensions = (ref: React.RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState({ width: 300, height: 169 });

  const calculateDimensions = () => {
    if (ref.current) {
      const boundingRect = ref.current.getBoundingClientRect();
      const { width, height } = boundingRect;
      setDimensions({ width: Math.round(width), height: Math.round(height) });
    }
  };

  useEffect(() => {
    calculateDimensions();

    function handleResize() {
      calculateDimensions();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return dimensions;
};

type VideoTileProps = {
  video: Video | LiveVideo;
  reverse?: boolean;
  displayTitle?: boolean;
  className?: string;
  imageWidth: number;
};
const VideoTile: React.FC<VideoTileProps> = ({
  video,
  reverse = false,
  displayTitle = true,
  className,
  imageWidth,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const dimensions = useRefDimensions(ref);

  const buttonStyles = {
    '--scale': dimensions.width,
    height: 'auto',
  };

  const titleClass = reverse ? classNames(styles.videoTitle, styles.reverse) : styles.videoTitle;
  const link = `/watch/${video.slug}/${video.id}?autoplay=true`;

  return (
    <Link to={link}>
      <div className={classNames(styles.videoTileContainer, className)}>
        <div className={styles.videoContainer}>
          <div ref={ref} className={styles.playButtonContainer}>
            <div className={styles.playButton} style={buttonStyles}>
              <Icon.Play color="white" />
              <span>{formatDateTime(video.duration, 'M:ss')}</span>
            </div>
          </div>
          <Image
            className={styles.thumbnail}
            src={imageUrl({
              id: video.thumbnailURL,
              width: imageWidth,
              height: Math.ceil((imageWidth * 9) / 16),
            })}
            width={imageWidth}
            height={Math.ceil((imageWidth * 9) / 16)}
            alt={'description' in video ? video.description || 'Latest news' : 'Latest news'}
          />
        </div>
        {displayTitle ? <p className={titleClass}>{video.title}</p> : null}
      </div>
    </Link>
  );
};

const VideoTileSkeleton = () => {
  return (
    <>
      <Skeleton type="image" />
      <Skeleton type="text" />
      <Skeleton type="word" />
    </>
  );
};

export { VideoTile, VideoTileSkeleton };
